@import 'src/styles/variables';

// Variables & keyframes

$size: 0.5;

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

// SCSS

div.loading-circle {
  display: inline-block;
  position: relative;
  width: calc(80px * $size);
  height: calc(80px * $size);

  div {
    animation: rotate 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: calc(40px * $size) calc(40px * $size);

    &:after {
      content: ' ';
      display: block;
      position: absolute;
      width: calc(7px * $size);
      height: calc(7px * $size);
      border-radius: 50%;
      background: $green;
      margin: -2px 0 0 -2px;
    }

    &:nth-child(1) {
      animation-delay: -0.036s;

      &:after {
        top: calc(63px * $size);
        left: calc(63px * $size);
      }
    }

    &:nth-child(2) {
      animation-delay: -0.072s;

      &:after {
        top: calc(68px * $size);
        left: calc(56px * $size);
      }
    }

    &:nth-child(3) {
      animation-delay: -0.108s;

      &:after {
        top: calc(71px * $size);
        left: calc(48px * $size);
      }
    }

    &:nth-child(4) {
      animation-delay: -0.144s;

      &:after {
        top: calc(72px * $size);
        left: calc(40px * $size);
      }
    }

    &:nth-child(5) {
      animation-delay: -0.18s;

      &:after {
        top: calc(71px * $size);
        left: calc(32px * $size);
      }
    }

    &:nth-child(6) {
      animation-delay: -0.216s;

      &:after {
        top: calc(68px * $size);
        left: calc(24px * $size);
      }
    }

    &:nth-child(7) {
      animation-delay: -0.252s;

      &:after {
        top: calc(63px * $size);
        left: calc(17px * $size);
      }
    }

    &:nth-child(8) {
      animation-delay: -0.288s;

      &:after {
        top: calc(56px * $size);
        left: calc(12px * $size);
      }
    }
  }
}
