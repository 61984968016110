html,
body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: hsl(195, 20%, 96%);

  *:not(i, .oi) {
    font-family: 'Commuter Sans', sans-serif;
  }
}

#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

h1,
h2,
h3,
h4 {
  color: $blue;
  font-family: inherit;
  font-weight: 700;
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 21px;
}

p {
  font-size: 13px;
}
