@font-face {
  font-family: 'Commuter Sans';
  src: url('../../public/fonts/commuters-sans-light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Commuter Sans';
  src: url('../../public/fonts/commuters-sans-regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Commuter Sans';
  src: url('../../public/fonts/commuters-sans-bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}
