@import 'src/styles/variables';

.dashboard-row {
  height: calc(50vh - 20px);

  .number-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    h1 {
      font-size: 40px;

      small {
        font-size: 14px;
      }
    }

    p, div {
      color: $green;
      font-weight: 700;
      font-size: 100px;
    }

    img {
      width: 50%;
    }
  }
}