body {
  padding: 20px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}

// Components

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 6px;
  background-color: white;
  box-sizing: border-box;
  width: 100%;
  padding: 20px;
  margin-bottom: 20px;
}